import XLSX from 'xlsx'
import { request } from '@/api'

export function multiMergeMapping (file, keyMap, layers = 2) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = ({ target }) => {
      const workbook = XLSX.read(target.result, {
        type: 'binary',
        cellDates: true
      })
      const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
        blankrows: true, raw: false, defval: null
      })
      if (!json.length) {
        return reject(new Error('数据为空'))
      }
      let mappingKeys = {}
      json.splice(0, layers).map(field => {
        Object.keys(field).forEach(key => {
          if (field[key]) {
            const index = keyMap.findIndex(v => v.title === field[key])
            if (index > -1) {
              console.log(key, ' => ', keyMap[index].title)
              mappingKeys[keyMap[index].title] = key
            }
          }
        })
      })
      console.log(keyMap)
      for (let i = 0; i < keyMap.length; i++) {
        if (keyMap[i].mapping === false || keyMap[i].import === false) {
          continue;
        }
        if (mappingKeys[keyMap[i].title] === undefined) {
          return reject(new Error(keyMap[i].title + '字段缺少映射'))
        }
        if (json[0][mappingKeys[keyMap[i].title]] === undefined) {
          return reject(new Error('缺少' + keyMap[i].title + '字段'))
        }
      }
      resolve(json.map(row => {
        const obj = {}
        keyMap.filter(v => v.import !== false).forEach(item => {
          if (item.mapping !== false) {
            if (row[mappingKeys[item.title]]) {
              obj[item.key] = item.convert ? item.convert(row[mappingKeys[item.title]], obj) : row[mappingKeys[item.title]]
            } else {
              obj[item.key] = item.default || null
            }
          } else {
            obj[item.key] = item.default || null
          }
        })
        return obj
      }))
    }
    reader.readAsBinaryString(file)
  })
}

export const type1columns = [
  { title: '姓名', key: 'name', fixed: 'left', width: 120, original: true },
  { title: '职务工资', key: 'zwgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '级别工资', key: 'jbgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '比例％', key: 'bl', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '折后工资', key: 'zhgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '警衔津贴', key: 'jxjt', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '交通费', key: 'jtf', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '回民补贴', key: 'hmbt', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '岗位津贴', key: 'gwjt', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '生活性补贴', key: 'shbt', sortable: true, ellipsis: true, tooltip: true, width: 140 },
  { title: '工作性津贴', key: 'gzbt', sortable: true, ellipsis: true, tooltip: true, width: 140 },
  { title: '农村艰苦地区津贴', key: 'ncbt', sortable: true, ellipsis: true, tooltip: true, width: 170 },
  { title: '基础绩效奖', key: 'jcjx', sortable: true, ellipsis: true, tooltip: true, width: 140 },
  { title: '应发工资合计', key: 'total', sortable: true, ellipsis: true, tooltip: true, width: 140 },
  { title: '住房公积金', key: 'zfgjj', sortable: true, ellipsis: true, tooltip: true, width: 150 },
  { title: '医疗保险', key: 'ylbx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '养老保险', key: 'ylaobx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '代扣个税', key: 'dkgs', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '职业年金', key: 'zynj', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣款1', key: 'dec1', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣款2', key: 'dec2', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣款3', key: 'dec3', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣款合计', key: 'dectotal', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '实发工资', key: 'realWage', sortable: true, ellipsis: true, tooltip: true, width: 120, original: true },
  { title: '人数', key: 'num', sortable: true, ellipsis: true, tooltip: true, width: 120 }
]

export const type2columns = [
  { title: '姓名', key: 'name', fixed: 'left', width: 120, original: true },
  { title: '基本工资', key: 'jcgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '工龄工资', key: 'glgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '考勤工资', key: 'kqgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '基本绩效', key: 'jcjx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '职务工资', key: 'zwgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '奖励工资', key: 'jlgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '加班补助', key: 'jbgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '夜班补助', key: 'ybbz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '通讯补助', key: 'txbz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '处突补助', key: 'ctbz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '艰苦岗位津贴', key: 'jkgwjt', sortable: true, ellipsis: true, tooltip: true, width: 150 },
  { title: '基础绩效奖', key: 'jcjxj', sortable: true, ellipsis: true, tooltip: true, width: 140 },
  { title: '应发工资', key: 'yfgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '小计', key: 'total', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '养老保险', key: 'ylaobx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '医疗保险', key: 'ylbx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '失业保险', key: 'sybx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '公积金', key: 'gjj', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣减部分1', key: 'dec1', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣减部分2', key: 'dec2', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '实发工资', key: 'realWage', sortable: true, ellipsis: true, tooltip: true, width: 120, original: true },
  { title: '备注', key: 'remark', ellipsis: true, tooltip: true, width: 200 },
  { title: '单位', key: 'org', ellipsis: true, tooltip: true, width: 200 },
]

export const type3columns = [
  { title: '单位', key: 'org', fixed: 'left', width: 180, ellipsis: true, tooltip: true },
  { title: '姓名', original: true, key: 'name', fixed: 'left', width: 120, ellipsis: true, tooltip: true },
  { title: '岗位工资', key: 'gwgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '薪级工资', key: 'xjgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '基础津贴', key: 'jcjt', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '奖励性绩效工资', key: 'jlgz', sortable: true, ellipsis: true, tooltip: true, width: 160 },
  { title: '加班工资', key: 'jbgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '职务工资', key: 'zwgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '夜班补助', key: 'ybbz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '通讯补助', key: 'txbz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '乡镇补贴', key: 'xzbt', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '基础绩效奖', key: 'jcjx', sortable: true, ellipsis: true, tooltip: true, width: 150 },
  { title: '应发工资', key: 'yfgz', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '小计', key: 'total', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '养老保险', key: 'ylaobx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '医疗保险', key: 'ylbx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '失业保险', key: 'sybx', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '公积金', key: 'gjj', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣减部分1', key: 'dec1', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '扣减部分2', key: 'dec2', group: 'config', sortable: true, ellipsis: true, tooltip: true, width: 120 },
  { title: '备注', key: 'remark', ellipsis: true, tooltip: true, width: 200 },
  { title: '实发工资', original: true, key: 'realWage', sortable: true, fixed: 'right', width: 150, ellipsis: true, tooltip: true },
]

export const columnConfigs = {}

export function getSysConfig (type, key, refresh = false) {
  if (!columnConfigs[type + '-' + key] || refresh) {
    columnConfigs[type + '-' + key] = request('/ucenter/sys_config', 'get', {
      type: 'cop_columns_' + type,
      key
    })
  }
  return columnConfigs[type + '-' + key]
}

export async function getColumnConfigs (type, key, refresh = false) {
  const res = await getSysConfig(type, key, refresh)
  let columns = [type1columns, type2columns, type3columns][type - 1]

  let dataList = []
  try {
    if (res.length) {
      dataList = JSON.parse(res[0].value)
      dataList = dataList.filter(v => v.title && v.key)
    }
  } catch (e) {
    console.error(e)
  }

  if (!dataList.length) {
    dataList = columns.filter(v => v.group === 'config').map(v => {
      return { title: v.title, key: v.key }
    })
  }

  const rules = {}
  const formData = {}
  dataList.forEach(item => {
    formData[item.key] = item.title
    rules[item.key] = [
      { required: true, message: '不能为空' }
    ]
  })

  columns = columns.map(item => {
    if (item.group === 'config') {
      let obj = {...item}
      obj.title = formData[item.key]
      return obj
    } else {
      return item
    }
  })

  return { rules, formData, dataList, columns }
}
