<style scoped lang="less">
  .cop-salary {
    &.flex {
      display: flex;
      flex-direction: column;
      .table-list {
        margin: 0 10px;
        background-color: #FFF;
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    margin: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #FFF;
    justify-content: space-between;
  }
</style>

<template>
  <div class="cop-salary flex">
    <div class="title">
      <fm-title style="margin: 0 10px;" :title-text="title" :note-text="'共' + showDataList.length + '项'"></fm-title>
      <Row type="flex" justify="space-between" :wrap="false" :gutter="10" style="margin: 0 10px;flex: 1;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10">
            <i-col>
              <i-input placeholder="请输入姓名" search @on-blur="onSearch(null)" @on-search="onSearch(null)" @on-clear="onSearch(null)" clearable v-model="queryForm.value">
                <span slot="prepend">姓名</span>
              </i-input>
            </i-col>
            <i-col>
              <DatePicker show-week-numbers :clearable="false" @on-change="onSearch(null, true)" v-model="currentMonth" type="month" placeholder="请选择月份" style="width: 100px"></DatePicker>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Row style="margin-left: 10px;" type="flex" :gutter="10" :wrap="false">
            <i-col><Button icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button></i-col>
            <i-col>
              <Poptip transfer confirm @on-ok="batchDel" title="确定删除当前所有数据吗？">
                <Button type="error" icon="md-trash" :loading="loading.del">批量删除</Button>
              </Poptip>
            </i-col>
            <i-col>
              <input type="file" ref="import" accept=".xlsx,.xls" style="display: none;" @input="importData" />
              <Button @click="$refs.import.click()" :loading="loading.sync" type="primary" icon="md-cloud-upload">导入</Button>
            </i-col>
            <i-col><Button icon="md-add-circle" @click="addClick" type="primary">新增</Button></i-col>
          </Row>
        </i-col>
      </Row>
    </div>
    <div class="table-list" ref="content">
      <Table ref="table" :loading="loading.load" :data="pageDataList" :columns="columns" :height="tableHeight">
        <template slot-scope="{ row }" slot="action">
          <template>
            <Button icon="ios-brush" type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
            <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
              <Button icon="md-trash" size="small" type="text">删除</Button>
            </Poptip>
          </template>
        </template>
      </Table>
    </div>
    <div style="margin: 0 10px;padding: 10px;background-color: #FFF;">
      <Page @on-change="num => page.num = num" :total="showDataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer draggable :mask-closable="false" mask transfer :title="formData.id ? '修改' : '新增'" width="40" placement="right" closable v-model="status.drawer">
      <fm-form label-alone label-align="left">
        <fm-form-item label="姓名">
          <Select :disabled="Boolean(formData.id)" clearable filterable v-model="formData.hrmsWageWorkerId" @on-change="onFormUserChange">
            <Option v-for="item in userList" :key="item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </fm-form-item>
        <!-- <fm-form-item label="类型">
          <RadioGroup v-model="formData.type">
            <Radio :label="1">正式</Radio>
            <Radio :label="2">辅警</Radio>
            <Radio :label="3">退役</Radio>
          </RadioGroup>
        </fm-form-item> -->
        <fm-form-item label="月份">
          <DatePicker :disabled="Boolean(formData.id)" show-week-numbers :clearable="false" v-model="formData.month" type="month" placeholder="请选择月份" style="width: 130px"></DatePicker>
        </fm-form-item>
        <fm-form-item label="实发工资">
          <InputNumber style="width: 100%;" v-model="formData.realWage"></InputNumber>
        </fm-form-item>
        <fm-form-item :label="item.title" v-for="item in formDataParams" :key="formData.type + '-' + item.key">
          <Input v-model="formData.detail[item.key]" :placeholder="'请输入' + item.title" />
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex;">
            <Button :loading="loading.update" style="flex: 1;" @click="status.drawer = false" type="default">取消</Button>
            <Button :loading="loading.update" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'
import { multiMergeMapping, getColumnConfigs } from './lib'
// import { multiMergeMapping, type1columns, type2columns, type3columns } from './lib'

function getDefault () {
  return {
    month: new Date(),
    hrmsWageWorkerId: null,
    type: 1,
    realWage: null,
    detail: {}
  }
}

function getDefaultQuery () {
  return {
    key: 'name',
    value: null
  }
}

export default {
  props: {
    type: { type: Number, default: 1 }
  },
  data () {
    return {
      dataList: [],
      userList: [],
      formData: getDefault(),
      query: getDefaultQuery(),
      queryForm: getDefaultQuery(),
      currentMonth: new Date(),
      columnConfigs: null,
      status: {
        drawer: false
      },
      page: {
        size: 10,
        num: 1
      },
      loading: {
        load: false,
        submit: false,
        import: false,
        del: false
      },
      tableHeight: null
    }
  },
  computed: {
    title () {
      if (this.type === 1) {
        return '警务人员工资表'
      } else if (this.type === 2) {
        return '警务辅警人员工资表'
      } else {
        return '城镇退役士兵工资表'
      }
    },
    columns () {
      let configs = this.columnConfigs
      let columns = configs ? configs.columns : []
      return [
        ...columns,
        { key: 'action', title: '操作', slot: 'action', import: false, width: 190, fixed: 'right' }
      ]
    },
    showDataList () {
      const key = this.query.key
      const value = this.query.value
      return this.dataList.filter(item => {
        if (key && value && item[key] && String(item[key]).indexOf(value) === -1) {
          return false
        }
        return true
      })
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.showDataList.slice(start, end)
    },
    formDataParams () {
      let configs = this.columnConfigs
      let columns = configs ? configs.columns : []
      return columns.filter(v => !v.original)
    }
  },
  watch: {
    type () {
      this.page.num = 1
      this.query = getDefaultQuery()
      this.loadData()
    }
  },
  methods: {
    onFormUserChange (id) {
      if (id) {
        const type = this.userList.find(v => v.id === id).type
        this.formData.type = type ? Number(type) : 1
      }
    },
    addClick () {
      this.formData = getDefault()
      this.formData.type = this.type
      this.status.drawer = true
    },
    onSearch (params, reload) {
      this.page.num = 1
      this.query = JSON.parse(JSON.stringify(this.queryForm))
      if (params) {
        Object.assign(this.query, params)
      }
      if (reload) {
        this.loadData()
      }
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.drawer = true
    },
    async submit () {
      if (!this.formData.month) {
        this.formData.month = this.currentMonth
      }
      if (this.formData.month instanceof Date) {
        this.formData.month = this.$datetime.format(this.formData.month, 'Y-M')
      }
      const formData = JSON.parse(JSON.stringify(this.formData))
      formData.detail = JSON.stringify(formData.detail)
      this.loading.submit = true
      try {
        if (this.formData.id) {
          await request('/basis/hrms_wage/' + this.formData.id, 'put', formData)
        } else {
          await request('/basis/hrms_wage', 'post', formData)
          this.query = getDefaultQuery()
        }
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
        this.page.num = 1
        this.formData = getDefault()
        this.status.drawer = false
      } catch (e) {
        this.loading.submit = false
      }
    },
    async itemDel (item) {
      try {
        await request('/basis/hrms_wage/' + item.id, 'delete')
        this.$Message.success('删除成功')
        this.loadData()
      } catch (e) {
        this.$Message.success('删除失败')
      }
    },
    async loadData () {
      this.loading.load = true
      const params = {
        month: this.$datetime.format(this.currentMonth, 'Y-M'),
        type: this.type
      }

      this.columnConfigs = await getColumnConfigs(this.type, this.$datetime.format(this.currentMonth, 'YM'))

      let dataList = await request('/basis/hrms_wage', 'get', params)

      this.dataList = dataList.map(item => {
        let obj = null
        try {
          obj = JSON.parse(item.detail)
        } catch (e) {
          obj = {}
        }
        item.type = item.type ? Number(item.type) : item.type
        item.detail = obj
        this.columns.filter(v => v.import !== false && !v.original).forEach(v => item[v.key] = obj[v.key])
        return item
      })
      this.loading.load = false
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    },
    async importData (e) {
      if (!e.target.files.length) {
        return
      }
      try {
        const data = await multiMergeMapping(e.target.files[0], this.columns)
        console.log(data)
        const month = this.$datetime.format(this.currentMonth, 'Y-M')
        const dataList = data.map(item => {
          let obj = {}
          let detail = {}
          this.columns.filter(v => v.original).forEach(v => obj[v.key] = item[v.key])
          this.columns.filter(v => v.import !== false && v.original !== true).forEach(v => detail[v.key] = item[v.key])
          obj.detail = JSON.stringify(detail)
          return obj
        })
        await request('/basis/hrms_wage/batch', 'post', {
          type: this.type, month, dataList
        })
        this.query = getDefaultQuery()
        this.page.num = 1
        this.loadData()
      } catch (e) {
        console.error(e)
        this.$Modal.error({
          title: '系统提示',
          content: e.message
        })
      }
      e.target.value = null
    },
    async batchDel () {
      this.loading.del = true
      try {
        await request('/basis/hrms_wage/batch', 'delete', {
          type: this.type,
          month: this.$datetime.format(this.currentMonth, 'Y-M')
        })
        this.loadData()
        this.$Message.success('删除成功')
      } catch (e) {
        this.$Modal.error({
          title: '系统提示',
          content: e.message
        })
      }
      this.loading.del = false
    }
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
    this.loadData()
    this.userList = await request('/basis/hrms_wage_worker', 'get')
  },
  destroyed () {
    this.dataList = []
    this.userList = []
    window.removeEventListener('resize', this.resize)
  }
}
</script>
